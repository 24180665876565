export default [
	{
		path: "/",
		redirect: "/dashboard",
		component: () => import("@/layout/Layout.vue"),
		children: [
			{
				path: "/dashboard",
				name: "dashboard",
				component: () => import("@/views/AdminDashboard.vue"),
			},
			{
				path: "/prospects-acquisition",
				name: "prospects-acquisition",
				component: () =>
					import("@/views/apps/admin/prospects/ContactQuestionLogsManager.vue"),
			},
			{
				path: "/prospects",
				name: "prospects",
				component: () => import("@/views/EmptyLayout.vue"),
				children: [
					{
						path: "",
						name: "prospects-manager",
						component: () =>
							import("@/views/apps/admin/prospects/ProspectsManager.vue"),
					},
					{
						path: ":id",
						name: "patient",
						component: () => import("@/views/apps/admin/prospects/Prospect.vue"),
					},
				],
			},
			{
				path: "/clinics",
				name: "clinics",
				component: () => import("@/views/EmptyLayout.vue"),
				children: [
					{
						path: "",
						name: "clinics-manager",
						component: () =>
							import("@/views/apps/admin/clinics/ClinicsManager.vue"),
					},
					{
						path: ":id",
						name: "clinic",
						component: () => import("@/views/apps/admin/clinics/Clinic.vue"),
					},
				],
			},
			{
				path: "/email-templates",
				name: "email-templates",
				component: () => import("@/views/EmptyLayout.vue"),
				children: [
					{
						path: "",
						name: "email-templates-manager",
						component: () =>
							import("@/views/apps/email-templates/EmailTemplatesManager.vue"),
					},
					{
						path: ":id",
						name: "email-template",
						component: () => import("@/views/apps/email-templates/EmailTemplate.vue"),
					},
					{
						path: "new",
						name: "new-email-template",
						component: () => import("@/views/apps/email-templates/EmailTemplate.vue"),
					},
				],
			},
			{
				path: "/contact-questions",
				name: "contact-questions",
				component: () => import("@/views/EmptyLayout.vue"),
				children: [
					{
						path: "",
						name: "contact-questions-manager",
						component: () =>
							import("@/views/apps/admin/contact-questions/ContactQuestionsManager.vue"),
					},
					{
						path: ":id",
						name: "contact-question",
						component: () => import("@/views/apps/admin/contact-questions/ContactQuestion.vue"),
					},
					{
						path: "new",
						name: "new-contact-question",
						component: () => import("@/views/apps/admin/contact-questions/ContactQuestion.vue"),
					},
				],
			},
			{
				path: "/tickets",
				name: "tickets",
				component: () =>
					import("@/views/apps/admin/tickets/TicketsManager.vue"),
			},
			{
				path: "/orders",
				name: "orders",
				component: () => import("@/views/EmptyLayout.vue"),
				children: [
					{
						path: "",
						name: "orders-manager",
						component: () => import("@/views/apps/admin/marketplace/OrdersManager.vue"),
					},
					{
						path: ":id",
						name: "order",
						component: () => import("@/views/apps/admin/marketplace/Order.vue"),
					}
				]
			},
			{
				path: "/invoices",
				name: "invoices",
				component: () =>
					import("@/views/apps/admin/marketplace/InvoicesManager.vue"),
			},
			{
				path: "/products",
				name: "products",
				component: () => import("@/views/apps/admin/marketplace/ProductsManager.vue"),
                // children: [
				// 	{
				// 		path: ":id",
				// 		name: "product",
				// 		component: () => import("@/views/apps/admin/marketplace/Product.vue"),
				// 	},
                // ]
			},
		],
	},
	{
		path: "/",
		component: () => import("@/components/page-layouts/Auth.vue"),
		children: [
			{
				path: "/sign-in",
				name: "sign-in",
				component: () =>
					import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
			},
			{
				path: "/sign-up",
				name: "sign-up",
				component: () =>
					import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
			},
			{
				path: "/password-reset",
				name: "password-reset",
				component: () =>
					import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
			},
		],
	},
	{
		// the 404 route, when none of the above matches
		path: "/404",
		name: "404",
		component: () => import("@/views/crafted/authentication/Error404.vue"),
	},
	{
		path: "/:pathMatch(.*)*",
		redirect: "/404",
	},
];
